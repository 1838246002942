import {
    DataGrid,
    GridColDef,
    GridToolbar,
} from "@mui/x-data-grid";
import React from "react";
import {useSnackbar} from "notistack"


interface ListProps {
    columns: GridColDef[],

    items: any[],

    processRowUpdate?: (newRow: any, oldRow: any) => Promise<any> | any;
    isLoading: boolean,
}

function DataList(props: ListProps) {
    const {enqueueSnackbar} = useSnackbar();

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        enqueueSnackbar(error.message, {variant: 'error'});
    }, []);

    return (
        <DataGrid
            rows={props.items}
            columns={props.columns}

            disableColumnFilter={false}
            disableColumnMenu={false}
            disableColumnSelector={true}
            disableDensitySelector={true}

            components={{ Toolbar: GridToolbar }}
            componentsProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: {
                        debounceMs: 500
                    },
                    csvOptions: {
                        disableToolbarButton: true,
                    },
                    printOptions: {
                        disableToolbarButton: true,
                    }
                },
            }}

            autoPageSize={true}
            loading={props.isLoading}

            experimentalFeatures={{newEditingApi: true}}
            editMode="row"
            processRowUpdate={props.processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
        />
    )
}

export default DataList
