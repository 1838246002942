/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CollectingBox } from '../models/CollectingBox';
import type { CollectingPlaceDaycare } from '../models/CollectingPlaceDaycare';
import type { CollectingPlaceDaycarePatch } from '../models/CollectingPlaceDaycarePatch';
import type { CollectingPlaceDaycareWaitList } from '../models/CollectingPlaceDaycareWaitList';
import type { DefaultNUKConsignment } from '../models/DefaultNUKConsignment';
import type { DHLShipmentOrderRequestShipmentDetails } from '../models/DHLShipmentOrderRequestShipmentDetails';
import type { ReturnOrder } from '../models/ReturnOrder';
import type { Shipment } from '../models/Shipment';
import type { ShipmentRequestOverride } from '../models/ShipmentRequestOverride';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CollectingPlaceDaycaresService {

    /**
     * Get Collecting Places
     * Gets all registered **Collecting Places**
     * @returns any Registered **Collecting Places**
     * @throws ApiError
     */
    public static getCollectingPlacesCollectingPlacesDaycaresGet(): CancelablePromise<Array<(CollectingPlaceDaycare | CollectingPlaceDaycareWaitList)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces/daycares',
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * Get Collecting Place
     * Gets registered **Collecting Places**
     * @param collectingPlaceId
     * @returns any Registered **Collecting Place**
     * @throws ApiError
     */
    public static getCollectingPlaceCollectingPlacesDaycaresCollectingPlaceIdGet(
        collectingPlaceId: string,
    ): CancelablePromise<(CollectingPlaceDaycare | CollectingPlaceDaycareWaitList)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces/daycares/{collecting_place_id}',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * Delete Collecting Place
     * Delete collecting place
     * @param collectingPlaceId
     * @returns any Successful deletion of **Collecting Place**
     * @throws ApiError
     */
    public static deleteCollectingPlaceCollectingPlacesDaycaresCollectingPlaceIdDelete(
        collectingPlaceId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/collectingPlaces/daycares/{collecting_place_id}',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * Patch Collecting Place
     * Updates details of collecting place
     * @param collectingPlaceId
     * @param requestBody
     * @returns CollectingPlaceDaycare Patched **Collecting Place**
     * @throws ApiError
     */
    public static patchCollectingPlaceCollectingPlacesDaycaresCollectingPlaceIdPatch(
        collectingPlaceId: string,
        requestBody: CollectingPlaceDaycarePatch,
    ): CancelablePromise<CollectingPlaceDaycare> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/collectingPlaces/daycares/{collecting_place_id}',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * Create Consignment
     * Performs the default consignment. It creates a collecting box for the specified **Collecting Place** with 1 return order and 1 DHL shipment.
     * @param collectingPlaceId
     * @param date
     * @returns DefaultNUKConsignment Successful Response
     * @returns any Created **Collecting Box**
     * @throws ApiError
     */
    public static createConsignmentCollectingPlacesDaycaresCollectingPlaceIdConsignmentPost(
        collectingPlaceId: string,
        date?: string,
    ): CancelablePromise<DefaultNUKConsignment | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/daycares/{collecting_place_id}/consignment',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            query: {
                'date': date,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * Get Collecting Boxes For Place
     * Gets all collecting boxes for a **Collecting Place**.
     * @param collectingPlaceId
     * @returns CollectingBox **Collecting Boxes** for school
     * @throws ApiError
     */
    public static getCollectingBoxesForPlaceCollectingPlacesDaycaresCollectingPlaceIdCollectingBoxesGet(
        collectingPlaceId: string,
    ): CancelablePromise<Array<CollectingBox>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces/daycares/{collecting_place_id}/collectingBoxes',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * Create Collecting Box
     * Creates a collecting box for the specified **Collecting Place**.
     *
     * A collecting box consists of
     * 0-n return orders to be sent back
     * 0-n DHL shipments to send the collecting box
     * @param collectingPlaceId
     * @param date
     * @returns CollectingBox Successful Response
     * @returns any Created **Collecting Box**
     * @throws ApiError
     */
    public static createCollectingBoxCollectingPlacesDaycaresCollectingPlaceIdCollectingBoxesPost(
        collectingPlaceId: string,
        date?: string,
    ): CancelablePromise<CollectingBox | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/daycares/{collecting_place_id}/collectingBoxes',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            query: {
                'date': date,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * Create Collecting Box Return
     * Creates a return for the specified **Collecting Box**.
     * @param collectingPlaceId
     * @param collectingBoxId
     * @param date
     * @returns ReturnOrder Successful Response
     * @returns any Created **Return Order**
     * @throws ApiError
     */
    public static createCollectingBoxReturnCollectingPlacesDaycaresCollectingPlaceIdCollectingBoxesCollectingBoxIdReturnsPost(
        collectingPlaceId: string,
        collectingBoxId: string,
        date?: string,
    ): CancelablePromise<ReturnOrder | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/daycares/{collecting_place_id}/collectingBoxes/{collecting_box_id}/returns',
            path: {
                'collecting_place_id': collectingPlaceId,
                'collecting_box_id': collectingBoxId,
            },
            query: {
                'date': date,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * Create Collecting Box Shipment
     * Creates a shipment for the specified **Collecting Box**.
     * IMPORTANT: DHL checks all addresses for their existence! If any address does not exist, this call returns a `400`.
     * @param collectingPlaceId
     * @param collectingBoxId
     * @param requestBody
     * @param date
     * @returns Shipment Successful Response
     * @returns any Created **Shipment**
     * @throws ApiError
     */
    public static createCollectingBoxShipmentCollectingPlacesDaycaresCollectingPlaceIdCollectingBoxesCollectingBoxIdShipmentsPost(
        collectingPlaceId: string,
        collectingBoxId: string,
        requestBody: DHLShipmentOrderRequestShipmentDetails,
        date?: string,
    ): CancelablePromise<Shipment | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/daycares/{collecting_place_id}/collectingBoxes/{collecting_box_id}/shipments',
            path: {
                'collecting_place_id': collectingPlaceId,
                'collecting_box_id': collectingBoxId,
            },
            query: {
                'date': date,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * Create Collecting Place Shipment
     * Creates a shipment for the specified **Collecting Place**.
     * This is used for all shipments other than shipments related to specific collecting boxes. For these, please use the specific route.
     * IMPORTANT: DHL checks all addresses for their existence! If any address does not exist, this call returns a `400`.
     * @param collectingPlaceId
     * @param requestBody
     * @param date
     * @returns Shipment Successful Response
     * @returns any Created **Shipment**
     * @throws ApiError
     */
    public static createCollectingPlaceShipmentCollectingPlacesDaycaresCollectingPlaceIdShipmentsPost(
        collectingPlaceId: string,
        requestBody: ShipmentRequestOverride,
        date?: string,
    ): CancelablePromise<Shipment | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/daycares/{collecting_place_id}/shipments',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            query: {
                'date': date,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * @deprecated
     * Get Collecting Places
     * Gets all registered **Collecting Places**
     * @returns any Registered **Collecting Places**
     * @throws ApiError
     */
    public static getCollectingPlacesCollectingPlacesGet(): CancelablePromise<Array<(CollectingPlaceDaycare | CollectingPlaceDaycareWaitList)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces',
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * @deprecated
     * Get Collecting Place
     * Gets registered **Collecting Places**
     * @param collectingPlaceId
     * @returns any Registered **Collecting Place**
     * @throws ApiError
     */
    public static getCollectingPlaceCollectingPlacesCollectingPlaceIdGet(
        collectingPlaceId: string,
    ): CancelablePromise<(CollectingPlaceDaycare | CollectingPlaceDaycareWaitList)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces/{collecting_place_id}',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * @deprecated
     * Delete Collecting Place
     * Delete collecting place
     * @param collectingPlaceId
     * @returns any Successful deletion of **Collecting Place**
     * @throws ApiError
     */
    public static deleteCollectingPlaceCollectingPlacesCollectingPlaceIdDelete(
        collectingPlaceId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/collectingPlaces/{collecting_place_id}',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * @deprecated
     * Patch Collecting Place
     * Updates details of collecting place
     * @param collectingPlaceId
     * @param requestBody
     * @returns CollectingPlaceDaycare Patched **Collecting Place**
     * @throws ApiError
     */
    public static patchCollectingPlaceCollectingPlacesCollectingPlaceIdPatch(
        collectingPlaceId: string,
        requestBody: CollectingPlaceDaycarePatch,
    ): CancelablePromise<CollectingPlaceDaycare> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/collectingPlaces/{collecting_place_id}',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * @deprecated
     * Create Consignment
     * Performs the default consignment. It creates a collecting box for the specified **Collecting Place** with 1 return order and 1 DHL shipment.
     * @param collectingPlaceId
     * @param date
     * @returns DefaultNUKConsignment Successful Response
     * @returns any Created **Collecting Box**
     * @throws ApiError
     */
    public static createConsignmentCollectingPlacesCollectingPlaceIdConsignmentPost(
        collectingPlaceId: string,
        date?: string,
    ): CancelablePromise<DefaultNUKConsignment | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/{collecting_place_id}/consignment',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            query: {
                'date': date,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * @deprecated
     * Get Collecting Boxes For Place
     * Gets all collecting boxes for a **Collecting Place**.
     * @param collectingPlaceId
     * @returns CollectingBox **Collecting Boxes** for school
     * @throws ApiError
     */
    public static getCollectingBoxesForPlaceCollectingPlacesCollectingPlaceIdCollectingBoxesGet(
        collectingPlaceId: string,
    ): CancelablePromise<Array<CollectingBox>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces/{collecting_place_id}/collectingBoxes',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * @deprecated
     * Create Collecting Box
     * Creates a collecting box for the specified **Collecting Place**.
     *
     * A collecting box consists of
     * 0-n return orders to be sent back
     * 0-n DHL shipments to send the collecting box
     * @param collectingPlaceId
     * @param date
     * @returns CollectingBox Successful Response
     * @returns any Created **Collecting Box**
     * @throws ApiError
     */
    public static createCollectingBoxCollectingPlacesCollectingPlaceIdCollectingBoxesPost(
        collectingPlaceId: string,
        date?: string,
    ): CancelablePromise<CollectingBox | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/{collecting_place_id}/collectingBoxes',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            query: {
                'date': date,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * @deprecated
     * Create Collecting Box Return
     * Creates a return for the specified **Collecting Box**.
     * @param collectingPlaceId
     * @param collectingBoxId
     * @param date
     * @returns ReturnOrder Successful Response
     * @returns any Created **Return Order**
     * @throws ApiError
     */
    public static createCollectingBoxReturnCollectingPlacesCollectingPlaceIdCollectingBoxesCollectingBoxIdReturnsPost(
        collectingPlaceId: string,
        collectingBoxId: string,
        date?: string,
    ): CancelablePromise<ReturnOrder | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/{collecting_place_id}/collectingBoxes/{collecting_box_id}/returns',
            path: {
                'collecting_place_id': collectingPlaceId,
                'collecting_box_id': collectingBoxId,
            },
            query: {
                'date': date,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * @deprecated
     * Create Collecting Box Shipment
     * Creates a shipment for the specified **Collecting Box**.
     * IMPORTANT: DHL checks all addresses for their existence! If any address does not exist, this call returns a `400`.
     * @param collectingPlaceId
     * @param collectingBoxId
     * @param requestBody
     * @param date
     * @returns Shipment Successful Response
     * @returns any Created **Shipment**
     * @throws ApiError
     */
    public static createCollectingBoxShipmentCollectingPlacesCollectingPlaceIdCollectingBoxesCollectingBoxIdShipmentsPost(
        collectingPlaceId: string,
        collectingBoxId: string,
        requestBody: DHLShipmentOrderRequestShipmentDetails,
        date?: string,
    ): CancelablePromise<Shipment | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/{collecting_place_id}/collectingBoxes/{collecting_box_id}/shipments',
            path: {
                'collecting_place_id': collectingPlaceId,
                'collecting_box_id': collectingBoxId,
            },
            query: {
                'date': date,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * @deprecated
     * Create Collecting Place Shipment
     * Creates a shipment for the specified **Collecting Place**.
     * This is used for all shipments other than shipments related to specific collecting boxes. For these, please use the specific route.
     * IMPORTANT: DHL checks all addresses for their existence! If any address does not exist, this call returns a `400`.
     * @param collectingPlaceId
     * @param requestBody
     * @param date
     * @returns Shipment Successful Response
     * @returns any Created **Shipment**
     * @throws ApiError
     */
    public static createCollectingPlaceShipmentCollectingPlacesCollectingPlaceIdShipmentsPost(
        collectingPlaceId: string,
        requestBody: ShipmentRequestOverride,
        date?: string,
    ): CancelablePromise<Shipment | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/{collecting_place_id}/shipments',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            query: {
                'date': date,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

}