/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReturnOrder } from '../models/ReturnOrder';
import type { ReturnOrderPatch } from '../models/ReturnOrderPatch';
import type { ReturnOrderReceived } from '../models/ReturnOrderReceived';
import type { Shipment } from '../models/Shipment';
import type { ShipmentRequestOverride } from '../models/ShipmentRequestOverride';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReturnsService {

    /**
     * Get Return Orders
     * Gets all issued **Return Orders**
     * @returns ReturnOrder Issued **Return Orders**
     * @throws ApiError
     */
    public static getReturnOrdersReturnsGet(): CancelablePromise<Array<ReturnOrder>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/returns',
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * Get Return Order
     * Gets **Return Order**
     * @param returnOrderId
     * @returns ReturnOrder Issued **Return Order**
     * @throws ApiError
     */
    public static getReturnOrderReturnsReturnOrderIdGet(
        returnOrderId: string,
    ): CancelablePromise<ReturnOrder> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/returns/{return_order_id}',
            path: {
                'return_order_id': returnOrderId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                404: `Not found.

                Possible error codes:

                | \`error_code\` | Description |
                |-----|-----|
                |\`not_found\` | Occurs if entity does not exist. |`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * Patch Collecting Place
     * Updates details of return
     * @param returnOrderId
     * @param requestBody
     * @param sendEmail
     * @returns ReturnOrder Patched **Return Order**
     * @throws ApiError
     */
    public static patchCollectingPlaceReturnsReturnOrderIdPatch(
        returnOrderId: string,
        requestBody: ReturnOrderPatch,
        sendEmail: boolean = true,
    ): CancelablePromise<ReturnOrder> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/returns/{return_order_id}',
            path: {
                'return_order_id': returnOrderId,
            },
            query: {
                'sendEmail': sendEmail,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * Receive Return
     * Mark **Return Order** as `received`. Please call with the return order `id`.
     *
     *
     *
     * *Fallback for legacy shipments:*
     * *You can also call this route with the `shipmentNumber`. If multiple shipments exist with this number, an error is returned.*
     * @param idOrShipmentNumber
     * @param requestBody
     * @param date
     * @param sendEmail
     * @returns ReturnOrder Updated **Return Order**
     * @throws ApiError
     */
    public static receiveReturnReturnsIdOrShipmentNumberReceivedPost(
        idOrShipmentNumber: string,
        requestBody: ReturnOrderReceived,
        date?: string,
        sendEmail: boolean = true,
    ): CancelablePromise<ReturnOrder> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/returns/{id_or_shipment_number}/received',
            path: {
                'id_or_shipment_number': idOrShipmentNumber,
            },
            query: {
                'date': date,
                'sendEmail': sendEmail,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request.

                Possible error codes:

                | \`error_code\` | Description |
                |-----|-----|
                |\`already_received\` | Occurs if shipment was already marked as received. |`,
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                404: `Not found.

                Possible error codes:

                | \`error_code\` | Description |
                |-----|-----|
                |\`not_found\` | Occurs if entity does not exist. |`,
                409: `Multiple **Return Orders** found for this shipment number (YES, this can happen! :/)

                Possible error codes:

                | \`error_code\` | Description |
                |-----|-----|
                |\`conflict\` | Occurs if there are multiple candidates to return. |`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * Get Return Order Label
     * Gets label for **Return Order**
     * @param returnOrderId
     * @param adjustToPrintFormat Defines if the label should be returned in 910-300-600 (103 x 199mm) even if it is originally in a different format.
     * @returns any PDF label of **Return Order**
     * @throws ApiError
     */
    public static getReturnOrderLabelReturnsReturnOrderIdLabelGet(
        returnOrderId: string,
        adjustToPrintFormat: boolean = false,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/returns/{return_order_id}/label',
            path: {
                'return_order_id': returnOrderId,
            },
            query: {
                'adjustToPrintFormat': adjustToPrintFormat,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                404: `Not found.

                Possible error codes:

                | \`error_code\` | Description |
                |-----|-----|
                |\`not_found\` | Occurs if entity does not exist. |`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

    /**
     * Create Return Order Shipment
     * Creates a shipment for the specified **Return Order**.
     * IMPORTANT: DHL checks all addresses for their existence! If any address does not exist, this call returns a `400`.
     * @param returnOrderId
     * @param requestBody
     * @param date
     * @returns Shipment Successful Response
     * @returns any Created **Shipment**
     * @throws ApiError
     */
    public static createReturnOrderShipmentReturnsReturnOrderIdShipmentsPost(
        returnOrderId: string,
        requestBody: ShipmentRequestOverride,
        date?: string,
    ): CancelablePromise<Shipment | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/returns/{return_order_id}/shipments',
            path: {
                'return_order_id': returnOrderId,
            },
            query: {
                'date': date,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }

}