/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ShipmentProvider {
    DHL = 'DHL',
    GLS = 'GLS',
}