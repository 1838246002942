import {
    GridActionsCellItem,
    GridColDef,
    GridRowModel,
    GridRowParams,
} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import renderCellExpand from "../../../common/tables/GridCellExpand";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {ReturnOrder, getReturns, patchReturn, getReturn} from "../../features/returns";
import {useSnackbar} from "notistack";
import DataList from "../../../common/tables/DataList";


function ReturnsList() {
    const {enqueueSnackbar} = useSnackbar();

    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [returns, setReturns] = useState<ReturnOrder[]>([]);

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 175,
        },
        {
            field: 'shipmentNumber',
            headerName: 'shipmentNumber',
            minWidth: 150,
            flex: 1,
            valueGetter: (params) => params.row.shipmentData.shipmentNumber,
            renderCell: renderCellExpand
        },
        {
            field: 'name',
            headerName: 'contactInfo.name',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.contactInfo && params.row.contactInfo.name,
            renderCell: renderCellExpand
        },
        {
            field: 'email',
            headerName: 'contactInfo.email',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.contactInfo && params.row.contactInfo.email,
            renderCell: renderCellExpand
        },
        {
            field: 'name1',
            headerName: 'name1',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.senderAddress.name1,
            renderCell: renderCellExpand
        },
        {
            field: 'name2',
            headerName: 'name2',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.senderAddress.name2,
            renderCell: renderCellExpand
        },
        {
            field: 'name3',
            headerName: 'name3',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.senderAddress.name3,
            renderCell: renderCellExpand
        },
        {
            field: 'streetName',
            headerName: 'streetName',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.senderAddress.streetName,
            renderCell: renderCellExpand
        },
        {
            field: 'houseNumber',
            headerName: 'houseNumber',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.senderAddress.houseNumber,
            renderCell: renderCellExpand
        },
        {
            field: 'postCode',
            headerName: 'postCode',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.senderAddress.postCode,
            renderCell: renderCellExpand
        },
        {
            field: 'city',
            headerName: 'city',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.senderAddress.city,
            renderCell: renderCellExpand
        },
        {
            field: 'country',
            headerName: 'country',
            minWidth: 50,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.senderAddress.countryISOCode,
            renderCell: renderCellExpand
        },
        {
            field: 'shipmentSent',
            headerName: 'shipmentSent',
            type: 'dateTime',
            width: 230,
            valueGetter: ({ value }) => value && new Date(value),
        },
        {
            field: 'shipmentDelivered',
            headerName: 'shipmentDelivered',
            type: 'dateTime',
            width: 230,
            valueGetter: ({ value }) => value && new Date(value),
        },
        {
            field: 'shipmentReceived',
            headerName: 'shipmentReceived',
            type: 'dateTime',
            width: 230,
            valueGetter: ({ value }) => value && new Date(value),
        },
        {
            field: 'weightReceived',
            headerName: 'weightReceived',
            type: 'number',
            editable: true
        },
        {
            field: 'canReceiveMail',
            headerName: 'canReceiveMail',
            type: 'boolean',
            valueGetter: (params) => params.row.confirmation && !params.row.unsubscription,
        },
        {
            field: 'date',
            headerName: 'date',
            type: 'dateTime',
            width: 230,
            valueGetter: ({ value }) => value && new Date(value),
        },
        {
            field: 'label',
            type: 'actions',
            headerName: 'Label',
            // @ts-ignore
            getActions: (params: GridRowParams) => {
                let actions: Array<typeof GridActionsCellItem> = []
                // @ts-ignore
                actions.push( <GridActionsCellItem icon={<PictureAsPdfIcon/>} label="Label"
                                                   onClick={downloadLabel(params.row.id)} showInMenu={false}/>)
                return actions
            }
        },
        {
            field: 'actions',
            type: 'actions',
            // @ts-ignore
            getActions: (params: GridRowParams) => {
                let actions: Array<typeof GridActionsCellItem> = []
                if(!params.row.shipmentReceived) {
                    // @ts-ignore
                    actions.push( <GridActionsCellItem icon={<DoneAllIcon/>} label="Received"
                                                        onClick={markAsReceived(params.row.id)} showInMenu={false}/>)
                }
                return actions
            }
        }
    ]

    useEffect(() => {
        reloadReturns()
    }, [])

    const reloadReturns = () => {
        setIsLoading(true)

        getReturns(sessionStorage.getItem("season"))
            .then((allReturns) => {
                setReturns(allReturns)
                setIsLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar(error.message, {variant: 'error'});
            })
    }

    const processRowUpdate = async (newRow: GridRowModel) => {
        const response = await patchReturn(newRow.id, {
            contactInfo: {
                name: newRow.name,
                email: newRow.email
            },
            senderAddress: {
                name1: newRow.name1,
                name2: newRow.name2,
                name3: newRow.name3,
                streetName: newRow.streetName,
                houseNumber: newRow.houseNumber,
                postCode: newRow.postCode,
                city: newRow.city,
            },
            weightReceived: newRow.weightReceived
        })
        enqueueSnackbar('Änderungen erfolgreich gespeichert', {variant: 'success'})
        return response
    }

    // taken from https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
    const b64toBlob = (b64Data: string, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data)
        const byteArrays = []

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize)

            const byteNumbers = new Array(slice.length)
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i)
            }

            const byteArray = new Uint8Array(byteNumbers)
            byteArrays.push(byteArray)
        }

        return new Blob(byteArrays, {type: contentType})
    }

    const downloadLabel = (returnId: string) => async () => {
        getReturn(returnId)
            .then((returnOrder) => {
                // @ts-ignore
                const file = b64toBlob(returnOrder.shipmentData.labelData, 'application/pdf')

                const element = document.createElement("a");
                element.href = URL.createObjectURL(file);
                element.download = "return-label-" + returnId + ".pdf";

                // simulate link click
                document.body.appendChild(element); // Required for this to work in firefox
                element.click();
            })
            .catch((error) => {
                enqueueSnackbar(error.message, {variant: 'error'});
            })
    }

    const markAsReceived = (returnId: string) => async () => {
        const response = await patchReturn(returnId, {
            shipmentReceived: new Date().toISOString(),
        })
        enqueueSnackbar('Änderungen erfolgreich gespeichert', {variant: 'success'})
        reloadReturns()
        return response
    }

    return (
        <DataList
            items={returns}
            columns={columns}
            isLoading={isLoading}
            processRowUpdate={processRowUpdate}
        />
    )
}

export default ReturnsList
