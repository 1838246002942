/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum CollectingPlaceType {
    DAYCARE = 'daycare',
    SCHOOL = 'school',
    RETAIL = 'retail',
    OTHER = 'other',
    SAMMELDRACHE = 'sammeldrache',
}